import React from 'react';
import Layout from '../components/Layout/Layout';
import LegalsContent from '../data/legals-content';
import Seo from '../components/SEO/SEO';

export default () => (
  <>
    <Layout
      className="centeredPage--TextPage"
      headlines={["Impressum"]}
    >
      <LegalsContent />
    </Layout>
  </>
);

export function Head() {
  return (
    <>
      <meta name="robots" content="noindex" />
      <Seo
        title='Impressum | Tiny House Helden'
        meta_description=
        {'Hier findest du alle gesetzlich notwendigen Informationen von ' +
          'Tiny House Helden. Finde bei uns den passenden Hersteller und mehr!'}
      />
    </>
  )
}