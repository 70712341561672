import React from 'react';

const Hide = (props) => {
  // debugger;

  if(props.invisible) {

  }
  
  return (
  props.useDisplay ? <span style={{ display: 'none' }}>{props.children}</span>
    :
    <span style={{
      position: 'absolute', left: 0, bottom: 0,
      color: 'transparent',
      fontSize: '1px',
      pointerEvents: 'none',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none'
    }}
    >
      {props.children}
    </span>

)};

export default Hide;