import React from "react"
import Hide from "../components/Hide/Hide"

// E-Mail SPAM Bot Protection
const eMail = (
  <>
    <Hide>
      Bei der nachfolgenden E-Mail-Adresse handelt es sich um eine Phantasie-Adresse für unerwünschte Werbung.
      Bitte ignorieren Sie diese: <br />
      <a style={{ textDecoration: 'none' }} href="mailto:notwanted2343@mymail.org">notwanted2343@mymail.org</a>
      <br />
      <br />
    </Hide>

    <Hide>Nachfolgend die korrekte E-Mail-Adresse: <br /></Hide>
    <Hide useDisplay>awm</Hide>
    hello
    <Hide useDisplay>weski</Hide>
    <Hide useDisplay>%$€</Hide>
    &#x40;&#116;&#105;&#110;&#121;&#45;&#104;&#111;&#117;&#115;&#101;&#45;&#104;&#101;&#108;&#100;&#101;&#110;
    <Hide useDisplay>er</Hide>
    .&#100;&#101; <br />
    <Hide useDisplay>hopefully it works</Hide>
  </>
)

const number = (
  <>
    <Hide>
      Bei der nachfolgenden Telefonnummer handelt es sich um eine Phantasie-Nummer für unerwünschte Werbung.
      Bitte ignorieren Sie diese: <br />
      <address>
        <a style={{ textDecoration: 'none' }} href="tel:+491479374217">+491479374217</a>
      </address>
      <br />
      <br />
    </Hide>
    <Hide>Nachfolgend finden Sie die korrekte Telefonnummer: <br /></Hide>

    <Hide useDisplay>+49</Hide>
    &#48;&#51;&#48; {" "}
    <Hide useDisplay>911</Hide>
    &#50;&#52;{" "}&#55;
    <Hide useDisplay>555</Hide>
    &#50;&#52;{" "}
    <Hide useDisplay>000</Hide>
    &#54;&#50;&#57;
  </>
)

const address = (
  <>
    <Hide>
      Bei der nachfolgenden Adresse handelt es sich um eine Phantasie-Adresse für Roboter,
      die unerwünschte Werbung verschicken:<br />

      <address>
        Mathias Sochweski <br />
        Kariner Straße 20 <br />
        66125 Saarbrücken <br />
      </address>
    </Hide>
    <div className="">
      <Hide>Nachfolgend finden Sie die korrekte Anschrift: <br /></Hide>
      &#x50;&#x61;&#x75;&#x6c; <Hide>&nbsp;&nbsp;&#160;</Hide> &#83;&#116;&#97;<Hide>&#8232;</Hide>&#99;&#104;&#117;&#114;<Hide>&#8232;</Hide>&#115;&#107;&#105;
      <br />
      &#67;&#104;&#111;<Hide>&#8232;</Hide>&#114;&#105;&#110;&#101;&#114; <Hide>&nbsp;&nbsp;&#160;</Hide> &#83;&#116;&#114;&#46; <Hide>&nbsp;&nbsp;&#160;</Hide> &#51;&#51;
      <br />
      &#49;&#48;&#52;&#51;&#53; {" "}<Hide>&nbsp;&nbsp;&#160;</Hide> &#66;&#101;&#114;&#108;&#105;&#110;
    </div>
  </>
);

export default class extends React.Component {
  state = {
    email: "lade email (JavaScript muss aktiviert sein)...",
    number: "lade Telefonnummer (JavaScript muss aktiviert sein)...",
    address: "lade Adresse (JavaScript muss aktiviert sein)..."
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.setState({ email: eMail })
      this.setState({ number: number })
    }, 2000);

    window.setTimeout(() => {
      this.setState({ address: address })
    }, 1000)

  }

  render() {
    return (
      <>
        {/* <h1>Impressum</h1> */}
        <h1>Angaben gem&auml;&szlig; &sect; 5 TMG</h1>
        <p>
          {this.state.address}
        </p>
        <h2>Kontakt</h2>
        <p>
          Telefon: {this.state.number}
          <br />
          E-Mail: {this.state.email}
        </p>
        <h2>Bildernachweis</h2>
        <p>
          Auf der Website kommen teilweise Fotos von Dritten zum Einsatz. Im
          Header-Bereich dieser Seite ist ein Bild des Tiny House Herstellers{" "}
          <a
            href="http://www.tinyhouse-baluchon.fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Baluchon
          </a>{" "}
          zu sehen. Weiterhin stammen die meisten Fotos bei der Hersteller-Übersicht direkt von den jeweiligen Websites der Hersteller oder aber die Fotos wurden
          uns direkt vom Hersteller zugeschickt. Weitere Bildnachweise lauten wie folgt:
        </p>
        <ul>
          <li>
            Beitragsbild bei "Minimalsimus: Mehr Leben mit weniger Besitz":

            <a target="_blank" rel="noopener noreferrer" href="https://labs.openai.com/"> Created by DALL E</a>
          </li>
          <li>
            Beitragsbild bei "Finanzierung eines Tiny Houses" und "Hürden und Grenzen in der Tiny House Welt":
            <a target="_blank" rel="noopener noreferrer" href="http://www.freepik.com"> Designed by Kues / Freepik</a>
          </li>
          <li>

            Beitragsbild bei "Bücher":{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://www.freepik.com/free-photos-vectors/people"> People photo created by Racool_studio - www.freepik.com</a>
          </li>
          <li>Diverse Fotos vom französischen Tiny House Hersteller Baluchon</li>
          <li>Einige Icons entnommen von
            <a target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/authors/dave-gandyDave"> Gandy</a> von
            <a target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/">www.flaticon.com</a> (licensed by CC 3.0 BY)
          </li>
          <li>
            Beitragbild bei "Finanzierzng eines Tiny Houses" und "Budget für dein Tiny House Kalkulieren":
            <a rel="noopener noreferrer" href="http://www.freepik.com">Designed by ijeab / Freepik</a>
          </li>
          <li>
            Heldin-Foto bei "Mitmachen!": {" "}
            <a rel="noopener noreferrer" href='https://www.freepik.com/vectors/background'>Background vector created by macrovector - www.freepik.com</a>
          </li>
          <li> Foto bei Produkte "Tiny Houses auf ebay": {" "}
            <a href='https://www.freepik.com/photos/house'>House photo created by wirestock - www.freepik.com</a>
          </li>
          <li> Foto bei Produkte "Bücher": {" "}
            <a href='https://www.freepik.com/photos/background'>Background photo created by jcomp - www.freepik.com</a>
          </li>
        </ul>
        <h2>Verantwortlich f&uuml;r den Inhalt nach &sect; 55 Abs. 2 RStV</h2>
        <p>Gleiche Person wie unter "Angaben gemäß § 5 TMG" erwähnt (erster Punkt im Impressum)</p>
        <h3>Haftung f&uuml;r Inhalte</h3>{" "}
        <p>
          Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
          f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
          gespeicherte fremde Informationen zu &uuml;berwachen oder nach
          Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
          hinweisen.
        </p>{" "}
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab
          dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
          m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
          werden wir diese Inhalte umgehend entfernen.
        </p>{" "}
        <h3>Haftung f&uuml;r Links</h3>{" "}
        <p>
          Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf
          deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir
          f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen.
          F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige
          Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
          Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
          Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte
          waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </p>{" "}
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>{" "}
        <h3>Urheberrecht</h3>{" "}
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
          Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
          bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
          Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den
          privaten, nicht kommerziellen Gebrauch gestattet.
        </p>{" "}
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
        <p>
          Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
        </p>
      </>
    )
  }
}
